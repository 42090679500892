<template>
    <div class="device-index">
        <div class="container-header">
            <div class="top-actions-group">
                <div class="action-left">
                    <el-form class="geology-form">
                        <el-select
                            v-if="deviceid == 0"
                            v-model="listfilter.deviceid"
                            filterable
                            remote
                            reserve-keyword
                            default-first-option
                            clearable
                            placeholder="请选择监测设备"
                            size="medium"
                            :remote-method="getDeviceList"
                            :loading="getDeviceLoading"
                            @change="onSearch"
                        >
                            <el-option
                                v-for="item in deviceSelect"
                                :key="item.Id"
                                :label="item.DeviceName"
                                :value="item.Id"
                            >
                            </el-option>
                        </el-select>

                        <el-date-picker
                            v-model="filterDaterange"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            size="medium"
                            style="width: 240px; margin-left: 10px"
                            value-format="yyyy-MM-dd"
                            @change="dateChange"
                        >
                        </el-date-picker>
                        <el-button
                            style="margin-left: 10px"
                            type="primary"
                            size="medium"
                            icon="el-icon-search"
                            @click="onSearch"
                            >查询</el-button
                        >
                    </el-form>
                </div>
                <div class="action-right">
                    <el-button
                        type="primary"
                        icon="el-icon-circle-plus-outline"
                        size="medium"
                        @click="onAdd"
                        >添加</el-button
                    >
                </div>
            </div>
        </div>

        <el-table
            class="list-table"
            ref="multipleTable"
            :data="listData"
            tooltip-effect="dark"
            style="width: 100%"
            :stripe="true"
            :border="false"
            v-loading="tableLoading"
        >
            <el-table-column
                label="设备名称"
                prop="DeviceName"
            ></el-table-column>
            <el-table-column
                label="设备编号"
                prop="DeviceCode"
            ></el-table-column>
            <el-table-column
                label="传感器编号"
                prop="SensorId"
            ></el-table-column>
            <el-table-column label="名称" prop="FieldName"></el-table-column>
            <el-table-column
                label="预警值"
                prop="WarningValue"
            ></el-table-column>
            <el-table-column
                label="最大预警值"
                prop="MaxWarningValue"
            ></el-table-column>
            <el-table-column label="预警等级" prop="WarningType">
                <template slot-scope="scope">
                    {{ formWarningTypeSelect[scope.row.WarningType - 1] }}
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="CreateTime" width="100">
                <template slot-scope="scope">
                    {{ scope.row.CreateTime | datetime }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                    <el-button
                        type="success"
                        icon="el-icon-edit"
                        size="mini"
                        @click="onEdit(scope.row)"
                        >修改</el-button
                    >
                    <el-button
                        type="warning"
                        icon="el-icon-error"
                        size="mini"
                        style="margin-left: 10px"
                        @click="onListDel(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            class="list-pagination"
            :page-size="page.pagesize"
            hide-on-single-page
            :total="page.total"
            :current-page.sync="page.pageindex"
            @current-change="onPageChange"
        >
        </el-pagination>

        <el-dialog
            :title="formtype == 'add' ? '添加预警' : '修改预警'"
            :visible.sync="dialogFormVisible"
            width="750px"
            custom-class="geology-dialog"
            :modal-append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <el-form
                ref="form"
                class="geology-form"
                :rules="formRules"
                :model="form"
                label-width="120px"
                style="padding-top: 20px"
            >
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="监测点" prop="DangerId">
                            <el-select
                                v-model="form.DangerId"
                                filterable
                                remote
                                reserve-keyword
                                default-first-option
                                placeholder="请选择监测点"
                                size="medium"
                                :remote-method="getDangerList"
                                :loading="getDangerLoading"
                                @change="onDangerChange"
                            >
                                <el-option
                                    v-for="item in dangerSelect"
                                    :key="item.Id"
                                    :label="item.DangerName"
                                    :value="item.Id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="设备" prop="DeviceId">
                            <el-select
                                v-model="form.DeviceId"
                                filterable
                                remote
                                reserve-keyword
                                default-first-option
                                placeholder="请选择监测设备"
                                size="medium"
                                :remote-method="getFormDeviceList"
                                :loading="getFormDeviceLoading"
                            >
                                <el-option
                                    v-for="item in formDeviceSelect"
                                    :key="item.Id"
                                    :label="item.DeviceName"
                                    :value="item.Id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="deviceType == '雨量计'">
                    <el-col
                        :span="10"
                        :offset="1"
                    >
                        <el-form-item label="年降雨量" prop="AvgRate">
                            <el-input
                                v-model="form.AvgRate"
                                size="small"
                                placeholder="请输入年降雨量"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20" v-if="!deviceAloneSetup">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="预警模型" prop="WarningConfig">
                            <el-select
                                style="width: 100%"
                                v-model="form.WarningConfig"
                                placeholder="请选择预警模型"
                                size="medium"
                            >
                                <el-option label="阈值" :value="0"> </el-option>
                                <el-option label="切线角" :value="1">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1" v-if="form.WarningConfig==1">
                        <el-form-item label="平均变形速率" prop="AvgRate">
                            <el-input
                                v-model="form.AvgRate"
                                size="small"
                                placeholder="请输入平均变形速率"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                

                <el-row v-if="!deviceAloneSetup && form.WarningConfig==0">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="传感器" prop="SensorId">
                            <el-input
                                v-model="form.SensorId"
                                size="small"
                                placeholder="请输入传感器"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="对应字段" prop="FieldName">
                            <el-select
                                style="width: 100%"
                                v-model="form.FieldName"
                                placeholder="请选择对应字段"
                                size="medium"
                            >
                                <el-option
                                    v-for="item in formWarningFieldSelect"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="!deviceAloneSetup && form.WarningConfig==0">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="预警值" prop="WarningValue">
                            <el-input
                                placeholder="请输入预警值"
                                v-model="form.WarningValue"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="最大预警值" prop="MaxWarningValue">
                            <el-input
                                placeholder="请输入最大预警值"
                                v-model="form.MaxWarningValue"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="预警等级" prop="WarningType">
                            <el-select
                                style="width: 100%"
                                v-model="form.WarningType"
                                placeholder="请选择预警等级"
                                size="medium"
                            >
                                <el-option
                                    v-for="(
                                        item, index
                                    ) in formWarningTypeSelect"
                                    :key="item"
                                    :label="item"
                                    :value="index + 1"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="预警时间" prop="TotleTime">
                            <el-autocomplete
                                style="width: 100px"
                                class="inline-input"
                                v-model="form.TotleTime"
                                :fetch-suggestions="fetchSuggestions"
                                placeholder="请输入预警时间"
                            ></el-autocomplete
                            ><span style="margin-left: 10px">分钟</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="23" :offset="1">
                        <el-form-item>
                            <el-button
                                type="primary"
                                size="medium"
                                @click="onSubmit"
                                >立即保存</el-button
                            >
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                style="margin-left: 10px"
                                @click="dialogFormVisible = false"
                                >取消</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import {
    getDeviceList,
    getDeviceWarning,
    addDeviceWarning,
    editDeviceWarning,
    delDeviceWarning,
} from "@/api/device";
import { getDangerList } from "@/api/base";
export default {
    data() {
        return {
            listData: [],
            tableLoading: false,

            filterDaterange: "",

            getDeviceLoading: false,
            deviceSelect: [],

            listfilter: {
                deviceid: "",
                fstarttime: "",
                fendtime: "",
            },
            page: {
                pagesize: 10,
                pageindex: 1,
                total: 0,
            },

            dialogFormVisible: false,

            getDangerLoading: false,
            dangerSelect: [],

            getFormDeviceLoading: false,
            formDeviceSelect: [],

            formtype: "add",
            form: {
                DeviceId: "",
                SensorId: 0,
                FieldName: "",
                Direction: "",
                WarningValue: "",
                MaxWarningValue: "",
                TotleTime: "0",
                WarningType: "",
                Marks: "",
                ContrastType: 0,
                Id: 0,
                WarningConfig:"",
            },
            formRules: {
                DeviceId: [
                    { required: true, trigger: "blur", message: "请选择设备" },
                ],
                SensorId: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入传感器",
                    },
                ],
                FieldName: [
                    { required: true, trigger: "blur", message: "请选择字段" },
                ],
                WarningValue: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入预警值",
                    },
                ],
                MaxWarningValue: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入最大预警值",
                    },
                ],
                WarningConfig: [
                  { required: true, trigger: "blur", message: "请选择预警模型" },
                ],
                WarningType: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请选择预警等级",
                    },
                ],
                TotleTime: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入预警时间",
                    },
                ],
                AvgRate: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入需要设置的值",
                    },
                ],
            },
            formWarningFieldSelect: [
                "v1",
                "v2",
                "v3",
                "v4",
                "v5",
                "v6",
                "v7",
                "v8",
            ],
            formWarningTypeSelect: ["黄色", "橙色", "红色"],
            warningTypeData: [],
        };
    },
    props: {
        deviceid: {
            type: [Number, String],
            default: 0,
        },
    },
    components: {},
    computed: {
        deviceAloneSetup: function () {
            var deviceTypes = ["雨量计"];
            var device = this.formDeviceSelect.filter(
                (item) => item.Id == this.form.DeviceId
            );
            if (device.length > 0) {
                var devicetype = device[0].DeviceType;
                return (
                    deviceTypes.filter((item) => item == devicetype).length > 0
                );
            }
            return false;
        },
        deviceType: function () {
            var device = this.formDeviceSelect.filter(
                (item) => item.Id == this.form.DeviceId
            );
            if (device.length > 0) {
                return device[0].DeviceType;
            }
            return "";
        },
    },
    methods: {
        getDeviceList(value) {
            this.getDeviceLoading = true;
            getDeviceList(1, 10, {
                fname: value,
            }).then((resdata) => {
                this.getDeviceLoading = false;
                if (resdata.code == 0) {
                    this.deviceSelect = resdata.data.data;
                }
            });
        },

        getDangerList(value) {
            this.getDangerLoading = true;
            getDangerList(1, 10, {
                fname: value,
            }).then((resdata) => {
                this.getDangerLoading = false;
                if (resdata.code == 0) {
                    this.dangerSelect = resdata.data.data;
                }
            });
        },
        getFormDeviceList(value) {
            this.getFormDeviceLoading = true;
            getDeviceList(1, 10, {
                dangerid: this.form.DangerId,
                fname: value,
            }).then((resdata) => {
                this.getFormDeviceLoading = false;
                if (resdata.code == 0) {
                    this.formDeviceSelect = resdata.data.data;
                }
            });
        },
        dateChange(value) {
            if (value) {
                var newdate = [];
                for (
                    let index = 0;
                    index < this.filterDaterange.length;
                    index++
                ) {
                    const element = this.filterDaterange[index];
                    newdate.push(element);
                }
                this.listfilter.fstarttime = newdate[0];
                this.listfilter.fendtime = newdate[1];
                this.getListData();
            } else {
                this.listfilter.fstarttime = "";
                this.listfilter.fendtime = "";
                this.getListData();
            }
        },
        onSearch() {
            this.page.pageindex = 1;
            this.getListData();
        },
        onPageChange(pageindex) {
            this.page.pageindex = pageindex;
            this.getListData();
        },
        getListData() {
            this.tableLoading = true;
            getDeviceWarning(
                this.page.pageindex,
                this.page.pagesize,
                this.listfilter
            )
                .then((resdata) => {
                    this.tableLoading = false;
                    if (resdata.code == 0) {
                        this.listData = resdata.data.data;
                        this.page.total = resdata.data.count * 1;
                    } else {
                        this.$message({
                            type: "error",
                            message: resdata.msg,
                        });
                    }
                })
                .catch(() => {
                    this.tableLoading = false;
                });
        },
        onListDel(item) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delDeviceWarning(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => {});
        },
        onAdd() {
            this.form = {
                DeviceId: "",
                SensorId: 0,
                FieldName: "",
                Direction: "",
                WarningValue: "",
                MaxWarningValue: "",
                TotleTime: "0",
                WarningType: "",
                Marks: "",
                ContrastType: 0,
                Id: 0,
            };
            this.formtype = "add";
            this.dialogFormVisible = true;
        },
        onEdit(item) {
            item.TotleTime = item.TotleTime + "";
            this.form = item;
            this.formDeviceSelect = [
                {
                    Id: item.DeviceId,
                    DeviceName: item.DeviceName,
                    DeviceType: item.DeviceType,
                },
            ];
            this.formtype = "edit";
            this.dialogFormVisible = true;
        },
        onDangerChange() {
            this.getFormDeviceList();
        },
        fetchSuggestions(queryString, cb) {
            cb([
                {
                    value: 10,
                },
                {
                    value: 30,
                },
                {
                    value: 60,
                },
            ]);
        },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.WarningValur = this.form.WarningValue;
                    if (this.formtype != "edit") {
                        addDeviceWarning(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.getListData();
                                    this.dialogFormVisible = false;
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    } else {
                        editDeviceWarning(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.getListData();
                                    this.dialogFormVisible = false;
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    }
                } else {
                    return false;
                }
            });
        },
    },
    created() {
        this.$store.dispatch("dictionary/getWarningType").then((data) => {
            this.warningTypeData = data;
        });
        this.getDeviceList();
        this.getDangerList();
        this.getListData();
    },
};
</script>